
.contact-page-container{
    margin: 80px 0 0 0;
    width: 100%;
    min-height: calc( 100vh - 80px);
    background-color: hsl(0, 0%, 96%);
    padding: 34px 80px 26px 80px;
}
.contact-page-row{
    width: 100%;
    height: 100%;
}
.info-contact-row{
    width: 100%;
    height: 100%;
}

.dog-card{
    display: block;
    width: 100%;
    margin: 0px 8px auto 0;
    height: 763px;
    box-shadow: 0 3px 96px rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    overflow: hidden;
}
.dog-card img{
    margin-left: 16px;
    margin-top: 17px;
    width: 100%;
    padding-left: 108px;
    transform: scaleX(-1) ;
    height: 729px;
    object-fit: cover;
    z-index: 2;
}

.born-hunter{
    position: absolute;
    bottom: 31px;
    right:-66px;
    transform: rotate(270deg);
    height: 137px;
    color: #E0E0E0;
    font-family: 'eurostile_condregular';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.26px;
    text-align: left;
    text-transform: uppercase;
    z-index: 5;
}
.born-hunter-hr{
    position: absolute;
    bottom: 32.5px;
    right: 41.1px;
    width: 66px;
    z-index: 1;
}

.info-card{
    display: block;
    width: 100%;
    margin: 0px 0px 20px 8px;
    height: 196px;
    box-shadow: 0 3px 96px rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
}
.info-card-row{
    width: 100%;
    height: 71px;
    padding: 0 39px 0 33px;
}
.info-header{
    display: block;
    padding-top: 30px;
    padding-left: 33px;
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 1.26px;
    text-align: left;
    text-transform: uppercase;

}
.gsm{
    text-align: left;
    width: 142px;
    height: 37px;
}
.email{
    width: 109px;
    height: 37px;
    text-align: left;
}
.address{
    width: 180px;
    height: 48px;
    text-align: left;
}
.head{
    margin: auto;
    height: 16px;
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.98px;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
}
.info{
    margin-top: 7px;
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.98px;
    text-align: left;
}


.contact-form{
    display: flex;
    margin: 0px 0px 0px 8px;
    width: 100%;
    height: 547px;
    box-shadow: 0 3px 96px rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    padding: 37px 33px 32px 33px;
}
.form-head{
color: #000000;
font-family: 'eurostile_condregular';
font-size: 18px;
font-weight: 600;
font-style: normal;
letter-spacing: 1.26px;
line-height: normal;
text-align: left;
text-transform: uppercase;
}
.form-info{
color: #a7a7a7;
font-family: 'eurostile_condregular';
font-size: 14px;
font-weight: 400;
font-style: normal;
letter-spacing: normal;
line-height: normal;
text-align: justify;
}
.contact-form .form-group input{
height: 41px;
margin-bottom: 16px;
border: 1px solid #eaeaea;
background-color: #f8f8f8;
}
.contact-form .form-group input::placeholder{
color: #aaaaaa;
font-family: 'eurostile_condregular';
font-size: 14px;
font-weight: 400;
text-align: left;
text-transform: uppercase;
}

.contact-form .form-group textarea{
margin-bottom: 25px;
border: 1px solid #eaeaea;
background-color: #f8f8f8;
}

.contact-form .form-group textarea::placeholder{
    color: #b4b4b4;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.28px;
    text-transform: uppercase;
}

.send-btn{
    margin-left: 65%;
    height: 42px;       
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.28px;
    text-transform: uppercase;
    border: 1px solid #000000;
    background-color: rgba(24, 24, 14, 0);
    color: #000000;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 1.32px;
    text-align: center;
    text-transform: uppercase;
}
.send-btn:hover{
    color: white;
    background-color: black;
}
.contact-footer{
    position: absolute;
    bottom: 111px;
}

.footer-kofs{
    margin: auto;
    margin-left: 80px;
}
.copyright{
    margin: auto 72px;
    text-align: right;
}
.copyright-by-kofs{
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
}
.copyright-2{
    color: #808080;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}

@media screen and (max-width:768px){
    .container{
        height: 100%;
    }
    .contact-page-container{
        width: 100%;
        padding: 30px 16px;
        margin-top: 60px;
    }
    .contact-page-row{
        width: 100%;
        margin: auto;
    }
    .dog-row{
        width: 100%;
        height: 350px;
    }
    .dog-card{
        width: 100%;
        height: 100%;
        padding-bottom: 10px;
    }
    .dog-card img{
        width: 110%;
        height: 100%;
        padding-bottom: 20px;
        
    }
    .born-hunter{
        position: absolute;
        bottom: 8%;
        font-size: 16px;
    }
    .born-hunter-hr{
        position: absolute;
        top: 80%;
    }
    .info-card-row{
        margin: auto;
        padding: 0 10px;
    }
    .info-card{
        margin: 30px auto;
        margin-top: 50px;
        height: 230px;
    }
    .info-header{
        padding-top: 30px;  
        padding-left: 24px;
        text-align: left;
    }
    .email{
        text-align: right;
    }
    .address{
        width: 100%;
    }
    .contact-form{
        margin: auto;
    }
    .send-btn{
        margin-top: -10%;
    }
}