.warranty-container{
    margin:auto;
    text-align: center;
    height: 1102px;
}
.warranty{
    text-align: center;
    margin: auto;
}
.warranty-header{
margin-top: 53px;
margin-bottom: 36px;
text-align: center;
height: 38px;
color: #6c6c6c;
font-family: 'eurostile_condregular';
font-size: 32px;
font-weight: 600;
text-align: center;
text-transform: uppercase;

}

.warranty-card{
    margin: auto;
    display: block;
    width: 739px;
    height: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    background-color: #ffffff;
    padding: 22px 24px;
    font-family: 'eurostile_condregular';
}
.form-control{

    color: #b4b4b4;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.28px;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
}

#warrantyDropdownButton{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    height: 48px;
    margin-bottom: 22px;
    border-radius: 7px;
    border: 1px solid #eaeaea;
    background-color: #ffffff;
    text-transform: none;
    color: #b4b4b4;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.28px;
    padding: 0 10px;
}
.warranty-dropdown .dropdown-menu{
    width: 100%;
}
.warranty-dropdown .dropdown-menu a{
    cursor: pointer;
}
.form-group label{
    float: left;
    margin-bottom: 10px;
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.28px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
}
.form-group select{
    height: 48px;
margin-bottom: 22px;
border-radius: 7px;
border: 1px solid #eaeaea;
background-color: #ffffff;
}

.form-group input{
height: 48px;
margin-bottom: 22px;
border-radius: 7px;
border: 1px solid #eaeaea;
background-color: #ffffff;
text-transform: none;
}
.form-group input::placeholder{
    width: 129px;
    height: 19px;
    color: #b4b4b4;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.28px;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
}

.form-group textarea{
margin-bottom: 22px;
border-radius: 7px;
border: 1px solid #eaeaea;
background-color: #ffffff;
}

.form-group textarea::placeholder{
    color: #b4b4b4;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.28px;
    text-transform: uppercase;
}
.custom-file input{
    margin-bottom: 22px;
    border-radius: 7px;
    border: 1px solid #eaeaea;
    background-color: #ffffff;
    font-family: 'eurostile_condregular';
    cursor: pointer;
}

.custom-file label{
    color: #b4b4b4;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.28px;
    text-transform: uppercase;
    text-align: left;
}
.btn{
    height: 48px;
    border-radius: 7px;
    background-color: #f5f5f5;
    color: #a3a3a3;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.28px;
    text-transform: uppercase;
    transition: all ease-in-out .2s ;
    border: none;
}
.btn-black{
    height: 48px;
    border-radius: 7px;
    background-color: black;
    color: white;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.28px;
    text-transform: uppercase;
    border: none;
}
.btn-black:hover{
    background-color: #f5f5f5;
    color: black;
    
}

@media screen and (max-width:768px){
    .warranty-container{
        width: 100%;
        margin: auto;
        padding: 0 16px;
    }
    .warranty{
        width: 100%;
        margin: auto;
    }
    .warranty-header{
        margin-top: 30px;
        margin-bottom: 0;
        font-size: 20px;
    }
    .warranty-card{
        width: 100%;
    }
    .form-label{margin-bottom: 5px;}
    .form-group select{margin-bottom:5px ;}
}