body{
    background: url(../images/Background.svg);
    height: 100%;
}
.cavalry-row{
    width: 100%;
    margin: 70px auto auto auto ;
    overflow: hidden;
}
/* SECTION-1 */
.section-1{
    width: 100%;
    margin: auto;
    height: 810px;
    background:  url(../images/Layer_4.png) no-repeat;
    background-size: cover;
}

.back-models-img{
    position: absolute;
    margin-top: 1.8%;
    margin-left: 5.5%;
    opacity: 1;
}
.back-models-btn{
    vertical-align: middle;
    border: none;
    background: none;
    color: white;
    position: absolute;
    margin-top: 1.5%;
    margin-left: 8%;
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.96px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    outline: none;
}
.cavalry-yellow-header{
    text-align: center;
    margin-top: 62px;
    color: #ffc400;
    font-family: 'eurostile_condregular';
    font-size: 48px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 4.32px;
    text-transform: uppercase;

}
.yellow-bottom-text{
    margin: auto;
    width: 636px;
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 1.54px;
    text-align: center;
    font-style: normal;
}
.cavalry-gun{
    position: absolute;
    margin: 67px auto auto auto;
    left: 50%;
    transform: translate(-50%);
    width: 80%;
    /* z-index: 2; */
}
.photo {
    width: auto;
    height: auto;
}
.loading-gif{
    position: absolute;
    margin: 67px auto auto auto;
    top: 200px;
    left: 50%;
    transform: translate(-50%);
    width: 100px !important;
    z-index: 2;
}
.cavalry-background-text{
    position: absolute;
    margin: auto;
    width: 100%;
    margin-top: 17%;
    transform: scale(1.08);
    opacity: 0.33;
    z-index: 0;
}

.calibre-buttons{
   display: flex;
   flex-direction: column;
   position: absolute;
   left: 0;
   bottom: 300px;
   width: 100%;
   justify-content: center;
   align-items: center;
   z-index: 3;
}
.calibre-buttons span{
    color: white;
}

.active-calibreBtn{
    background-color: none;
    border: none;
    background: none;
    outline: none;
    color: white;
    font-size: 22px;
    margin: 12px 16px auto 8px;
    font-weight: 900;
}
.inactive-calibreBtn{
    border: none;
    background: none;
    outline: none;
    color: gray;
    font-size: 20px;
    margin: 12px 8px auto 8px;
    font-weight: 100;
}
.active-calibreBtn:focus , .inactive-calibreBtn:focus, .back-models-btn:focus, .active-calibreBtn-technical:focus , .inactive-calibreBtn-technical:focus{
    outline: none;
}
.active-btn-dropdown{
    background-color: black !important;
}
.model-buttons{
    margin: auto;
    opacity: 1;
    z-index: 10;
    margin-top: 20px;
}
.inactive-btn{
    margin: auto 16px;
    padding: 5px 10px;
    background-color: #ffffff21;
    color: white;
    opacity: 0.5;
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 2.88px;
    line-height: normal;
    text-align: left;
    border: none;
    outline: 8px solid rgba(255, 255, 255, 0.08);
    z-index: 10;
    cursor: pointer;
}
.active-btn{
    margin: auto 16px;
    padding: 5px 10px;
    background-color: white;
    border: none;
    outline: 8px solid rgba(255, 255, 255, 0.08);
    color: black;
    font-family: 'eurostile_condregular';
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 2.88px;
    line-height: normal;
    text-align: left;
    z-index: 10;
    cursor: pointer;
}
button:focus{
    outline: none;
    outline: 8px solid rgba(255, 255, 255, 0.08);
}
.model-buttons-dropdown{
    display: none;
}

.gauge-versions-text{
    margin-top: 350px;
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.12px;
    line-height: normal;
    text-align: center;
}
.gauge-numbers{
    margin: auto;
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 1.54px;
    line-height: normal;
    text-align: center;
}
.gauge-numbers span{
    padding-left: 20px;
}

.bottom-buttons{
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    z-index: 2;
}
.bottom-buttons a{
    margin: auto 66px;
    padding: 5px 10px;
    border: none;
    background: none;
    color: white !important;
    cursor: pointer;
}

.bottom-cloud{
    width: 100%;
    margin: auto;
    position: absolute;
    left: 0;
    bottom: 0;
}
.bottom-background{
    margin: auto;
    width: 100%;
    position: absolute;
    left: 0%;
    bottom: 0%;
    background-image: linear-gradient(180deg, rgba(28, 28, 28, 0) 0%, #1c1c1c 100%);
    transform: scale(1.1);
}
.model-background-text{
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 400px;
    font-weight: bolder;
    text-transform: uppercase;
    color: grey;
    opacity: 0.2;
    font-family: 'eurostile_condregular';
}

/* SECTION-2 */
/* part-1 */
.section-2{
    border-top: 1px solid rgba(209, 209, 209, 0.205);
    margin-top: 90px;
    height: auto;
    width: 100%;
    padding: 0;
}
.featureLTR .feature {
    background-image: linear-gradient(to right, #1c1c1c 0%, #1c1c1c 10%, rgba(28, 28, 28, 0) 100%);
    align-items: flex-start;
    box-sizing: border-box;
    padding-left: 80px;
  }
  .featureLTR .featureImage {
    align-items: flex-end;
  }
  .featureLTR .featureImage .productImage {
    right: 0px;
    /* padding-top: 160px; */
    transform-origin: right;
  }
  .featureLTR .featureImage .podiumVector img {
    right: -50%;
  }
  
  .featureRTL .feature {
    background-image: linear-gradient(to left, #1c1c1c 0%, #1c1c1c 10%, rgba(28, 28, 28, 0) 100%);
    align-items: flex-end;
    box-sizing: border-box;
    padding-right: 80px;
  }
  .featureRTL .featureImage {
    align-items: flex-start;
  }
  .featureRTL .featureImage .productImage {
    left: 0px;
    transform-origin: left;
  }
  .featureRTL .featureImage .podiumVector img {
    left: -50%;
  }
  
  .featureSection {
    display: flex;
    position: relative;
  }
  .featureSection .feature {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .featureSection .feature h2 {
    margin-bottom: 30px;
  }
  .featureSection .featureImage {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }
  .featureSection .featureImage .productImage {
    position: absolute;
    transform: scale(2);
  }
  .featureSection .featureImage .podiumVector {
    z-index: -10;
  }
  .featureSection .featureImage .podiumVector img {
    position: relative;
  }




.header-layer{
    margin: auto;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 297px;
    height: 59px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    background-color: rgba(255, 255, 255, 0.08);
    text-align: center;
    z-index: 5;
}
.header-layer-2{
    margin: 8px;
    width: 281px;
    height: 43px;
    background-color: #ffffff;
}
.header-layer p{
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 34px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 5.44px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
}

.gauge-shadow-1{
    color: #ffc400;
    font-family: 'eurostile_condregular';
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 5.44px;
    text-align: center;
    text-transform: uppercase;
   text-shadow: 0px 0px 40px #ffc400;
}
.gauge-text-1{
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.44px;
    line-height: normal;
    text-align: left;
    z-index: 3;
}
.section-2-cavalry-img-1{
    position: absolute;
    margin: auto;
    width: 100%;
    height: auto;
    transform: scale(2.15);
    margin-left: -5.5%;
    margin-top: 31.7%;
    z-index: 1;
}
.shadow-left{
    position: absolute;
    left: 0%;
    z-index: 2;
}
.circle-right{
    width:75%;
    position: absolute;
    top: -7%;
    left: 59%;
    z-index: -1;
    opacity: 0.8;
}

/* part-2 */
.gauge-shadow-2{
    color: #ffc400;
    font-family: 'eurostile_condregular';
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 5.44px;
    text-align: center;
    text-transform: uppercase;
   text-shadow: 0px 0px 40px #ffc400;
}
.gauge-text-2{
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.44px;
    line-height: normal;
    z-index: 3;
}
.section-2-cavalry-img-2{
    position: absolute;
    margin: auto;
    width: 100%;
    transform: scale(2.15);
    margin-left: -85%;
    margin-top: 82%;
    z-index: 1;
}
.shadow-right{
    position: absolute;
    right:-3%;
    top:52% ;
    transform: scaleX(-1);
    z-index: 2;
}
.circle-left{
    width:75%;
    position: absolute;
    top: 30%;
    left: -33.5%;
    z-index: -1;
    opacity: 0.8;
}

/* SECTION-3 */
.section-3{
    margin-top: 100px;
    width: 100%;
    height: auto;
    border-top: 1px solid rgba(209, 209, 209, 0.205);
}
.header-layer-section-3{
    margin: auto;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 517px;
    height: 59px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    background-color: rgba(255, 255, 255, 0.08);
    text-align: center;
    z-index: 5;
}
.header-layer-3{
    margin: 8px;
    width: 501px;
    height: 43px;
    background-color: #ffffff;
}
.model-buttons-dropdown-tech{
    display: none;
}
.header-layer-3 p{
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 34px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 5.44px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
}
.detail-container{
    margin-top: 250px;
    padding: 0 50px;
}
.detail-row{
    margin: auto;   
    width: 100%;
}
.detail-div{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
hr.solid {
    border-top: 0.5px solid rgba(187, 187, 187, 0.342);
    width: 95%;
    margin-top:0;
  }
.detail-header{
    text-align: left;
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.84px;
    text-transform: uppercase;
}
.detail-value{
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 15px;
    font-weight: 400;
    text-align: left;
}
.grey-text-bottom{
    position: relative;
    margin-left: 20px;
    margin-top: 51px;
    width: 632px;
    height: 37px;
    color: #acacac;
    font-family: 'eurostile_condregular';
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}
.calibre-buttons-technical{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 70px;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.calibre-buttons-technical span{
   color: white;
   margin-bottom: 5px;
}
.active-calibreBtn-technical{
    background-color: none;
    border: none;
    background: none;
    outline: none;
    color: white;
    font-size: 22px;
    margin: 0px 8px auto 8px;
    font-weight: 900;
}
.inactive-calibreBtn-technical{
    background-color: none;
    border: none;
    background: none;
    outline: none;
    color: grey;
    font-size: 20px;
    margin: 0px 8px auto 8px;
    font-weight: 100;
}
.model-buttons-technical{
    margin-top: 20px;
}

/* SECTION-4 */
.section-4{
    border-top: 1px solid rgba(209, 209, 209, 0.205);
    width: 100%;
    height: auto;
    margin-bottom: 110px;
    margin-top: 100px;

}
.header-layer-section-4{
    margin: auto;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 317px;
    height: 59px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    background-color: rgba(255, 255, 255, 0.08);
    text-align: center;
    z-index: 5;
}
.header-layer-4{
    margin: 8px;
    width: 301px;
    height: 43px;
    background-color: #ffffff;
}

.header-layer-4 p{
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 34px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 5.44px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
}

.receivers-row{
    padding-top: 130px;
    width: 100%;
    
}
.receivers-div{
    border: none;
    padding: 7px;
    
}
.receivers-div img{
    width: 100%;
    height: 184px;
    object-fit: cover;
    background-color: #222222;
    cursor: pointer;
    
}
.rec-number{
    position: absolute;
    color: black;
    left: 20px;
    bottom: 0;
    text-shadow: 0 0 10px white;
}   
.receiver-img-carousel{
    position: relative;
    width: 80vw;
    margin: auto;
}
.receiver-title{
    position: absolute;
    left: 20px;
    bottom: 20px;
    font-size: 20px;
}

/* SECTION-5 */
.section-5{
    border-top: 1px solid rgba(209, 209, 209, 0.205);
    width: 100%;
    height: 653px;
    margin-top: 50px;
}
.header-layer-section-5{
    margin: auto;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 517px;
    height: 59px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    background-color: rgba(255, 255, 255, 0.08);
    text-align: center;
    z-index: 5;
}
.header-layer-5{
    margin: 8px;
    width: 501px;
    height: 43px;
    background-color: #ffffff;
}

.header-layer-5 p{
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 34px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 5.44px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
}
.slider{
    margin: 100px auto auto auto;

}

.slider-card{
    background-color: rgba(69, 69, 69, 0);
    margin: auto 150px;
    border-radius: 7px;
    border: 2px solid #6a6a6a;
    height: 378px;
}
.slider-card-div{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: auto 188px;
}
.slider-card:hover{
    background-color: white;
}

.slider-card:hover .slider-card-img{
    transform: rotate(2deg) scale(1.2);
    transition:all ease-in-out 0.3s;
}
.slider-card:hover .slider-card-text{
    color: black;
    position: absolute;
    bottom: 50px;
    left: 100px;
    
}
.slider-card:hover .slider-arrow{
    left: 40px;
    color: black
   
}

.slider-card-img{
    position: absolute;
    width: 80%;
    height: 100%;
    object-fit: contain;
    z-index: 2;
    transition: all ease-in-out 0.3s;
    transform-origin: center;
}
.slider-card-text{
    position: absolute;
    color: #414141;
    font-family: 'eurostile_condregular';
    font-size: 100px;
    font-weight: 600;
    letter-spacing: 9.81px;
    text-align: center;
    text-transform: uppercase;
    z-index: 0;
    bottom: initial;
    left: initial;
    transition:all ease-in-out 0.3s;
}
.slider-arrow{
    display: block;
    position: absolute;
    left: 0%;
    font-size: 28px;
    font-weight: lighter;
    color: rgba(69, 69, 69, 0.055);
    z-index: 100;
    transition: all ease-in-out 0.2s;
    margin: 0;
    height: unset;
    color:rgba(69, 69, 69, 0);
}
.slider-dot1{
    position: absolute;
    left: 16px;
    top: 16px;
}
.slider-dot2{
    position: absolute;
    right: 16px;
    top: 16px;
}
.slider-dot3{
    position: absolute;
    left: 16px;
    bottom: 16px;
}
.slider-dot4{
    position: absolute;
    right: 16px;
    bottom: 16px;
}

.carousel-indicators{
    top: 110%;
    height: 8px;
    outline: none; 
}
.carousel-indicators li{
    width: 9px;
    height: 8px;
    border: 8px solid;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0.5;
}
.carousel-indicators .active{
    border: 8px solid;
    border-radius: 25px;
    width: 26px;
    background: #ffffff;
    outline: none;
}
.carousel-control-prev-icon{
    background-image: url(../images/arrow-right-models.png);
    width: 30px;
    height: 10px;
    transform: scaleX(-1);
    margin-right: 80px;
}
.carousel-control-next-icon{
    background-image: url(../images/arrow-right-models.png);
    width: 30px;
    height: 10px;
    margin-left: 80px;
}

/* SECTION-6 */
.section-6{
    margin: auto;
    text-align: center;
    height: 350px;
}
.social-accounts{
color: #808080;
font-family: 'eurostile_condregular';
font-size: 16px;
font-weight: 400;
text-align: center;
text-transform: uppercase;
margin-bottom: 56px;
}
.social-accounts span{
    padding-left: 80px;
    font-family: 'eurostile_condregular';
}

.kofs-footer{
    margin-bottom: 56px;
}
.footer-cloud{
   position: absolute;
   bottom: 0%;
   opacity: 0.2;
   transform: scale(1.1);
   z-index: -50;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .section-1{
        height: 700px;
    }
}


@media screen and (max-width:768px){
    .section-1{
        height: 493px;
        background-size: cover;
    }
    .cavalry-row{
        margin: 60px auto auto auto;
    }
    .cavalry-yellow-header{
        margin-top: 68px;
        height: 37px;
        color: #ffc400;
        font-family: 'eurostile_condregular';
        font-size: 30px;
        font-weight: 600;
        font-style: normal;
        letter-spacing: 2.7px;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
    }
    .yellow-bottom-text{
        margin-top: 31px;
        width: 100%;
        height: 15px;
        color: #ffffff;
        font-family: Eurostile;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.84px;
        line-height: normal;
        text-align: center;
    }
    .back-models-btn{
        margin-top: -10%;
        margin-left: 10%;
    }
    .back-models-img{
        margin-top: -8.5%;
        margin-left: 3.5%;
        width: 5%;
        height: 1.5%;
    }
    .model-buttons , .active-btn, .inactive-btn{
        display: none;
    }
    .model-background-text{
        position: absolute;
        top: 80%;
        font-size: 120px;
    }
    .cavalry-gun{
        width: 90%;
        height: 53px;
    }
    .calibre-buttons{
        position: absolute;
        bottom: 160px;
        width: 100%;
        z-index: 10;
    }
    .calibre-buttons button{
        margin-top: 0;
    }
    .model-buttons-dropdown{
        display: block;
        position: absolute;
        bottom: 120px;
        left: 50%;
        transform: translate(-50%);
        z-index: 10;
        cursor: pointer;
    }
    .dropdown-toggle{
        background-color: white;
        border: none;
        outline: 8px solid rgba(255, 255, 255, 0.08);
        color: black;
        font-family: 'eurostile_condregular';
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 2.88px;
        line-height: normal;
        text-align: center;
        padding: 0 10px;
    }
    
    .bottom-background{
        position: absolute;
        margin: auto;
        width: 100%;
        bottom: 4.9%;
        transform: scale(1.1);
    }
    .bottom-buttons{
        display: block;
        position: absolute;
        background-color: #1c1c1c;
        width: 100%;
        bottom:-20px;
        margin: auto 0;
        padding: 0 0;
    }
    .bottom-buttons a{
        margin: auto 10px;
        width: 84px;
        height: 17px;
        color: #ffffff !important;
        font-family: 'eurostile_condregular';
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.98px;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
    }
    /* section-2 */
    .section-2{
        height: 697px;
        margin-top: 70px;
    }
    .featureSection .featureImage .productImage {
        transform: scale(0.5);
      }
      .featureLTR .feature {
        padding-left: 16px;
    }
    .featureRTL .feature{
        padding-right: 16px;
    }
    .header-layer{
        width: 197px;
        height: 39px;
        margin-top: 10px;
    }
    .header-layer-2{
        width: 181px;
        height: 23px;
    }
    .header-layer-2 p{
        width: 100%;
        height: 22px;
        color: #000000;
        font-family: 'eurostile_condregular';
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 2.88px;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
    }
    .shadow-right{
        width: 80%;
        top: 59%;
    }
    .shadow-left{
        position: absolute;
        top: 12%;
        width: 80%;
    }
    .gauge-text-1, .gauge-text-2{
        color: #ffffff;
        font-family: 'eurostile_condregular';
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.96px;
        line-height: normal;
    }
    .gauge-shadow-1, .gauge-shadow-2{
        color: #ffc400;
        font-family: 'eurostile_condregular';
        font-size: 25px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 5.44px;
        line-height: normal;
        text-transform: uppercase;
    }
    .section-2-cavalry-img-1{
        position: absolute;
        margin-top: 145px;
    }
    .circle-right{
        position: absolute;
        top: 2%;
    }
    .section-2-cavalry-img-2{
        position: absolute;
        margin-top: 460px;
    }
    .circle-left{
        position: absolute;
        top: 46%;
    }
    .header-layer-section-3{
        width: 313px;
        height: 39px;
        margin-top: 10px;
    }
    .header-layer-3{
        width: 297px;
        height: 23px;
    }
    .header-layer-3 p{
        margin: auto;
        width: 222px;
        height: 22px;
        color: #000000;
        font-family: 'eurostile_condregular';
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 2.88px;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
    }
    .section-3{
        width: 100%;
        margin: auto;
        padding: 0;
    }
    .calibre-buttons-technical{
        position: absolute;
        left: 0;
        top: 40px;
    }
    .detail-container{
        width: 100%;
        margin-top: 180px;
        margin-bottom: 84px;
        padding: 0 15px;
    }
    .detail-row{
        text-align: left;
    }
    .detail-div{
        text-align: left;
    }
    .detail-header p{
        width: 46px;
        height: 17px;
        color: #ffffff;
        font-family: 'eurostile_condregular';
        font-size: 14px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: left;
        text-transform: uppercase;
    }
    .detail-value{
        width: 100%;
        height: 15px;
        color: #ffffff;
        font-family: 'eurostile_condregular';
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: justify;
    }
    hr.solid{
        display: none;
    }
    .grey-text-bottom{
        width: 100%;
    }
    .model-buttons-dropdown-tech{
        display: block;
        position: absolute;
        top: 120px;
        left: 50%;
        transform: translate(-50%);
        z-index: 10;
        cursor: pointer;
    }
    /* SECTION-4 */
    .section-4{
        /* height: 100%; */
        width: 100%;
        margin-bottom: 100px;
    }
    .header-layer-section-4{
        width: 207px;
        height: 39px;
        margin-top: 10px;
    }
    .header-layer-4{
        width: 191px;
        height: 23px;
    }
    .header-layer-4 p{
        margin: auto;
        width: 120px;
        height: 22px;
        color: #000000;
        font-family: 'eurostile_condregular';
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 2.88px;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
    }
    .receivers-container{
        padding: 40px 0;
        margin: 40px auto 0px auto;
    }
    .receivers-row{
        width: 100%;
        margin: 0 auto 0 auto;
        padding: 0;
    }
    

    /* SECTION-5 */
    .section-5{
        height: auto;
        margin-bottom: 150px;
    }
    .header-layer-section-5{
        width: 313px;
        height: 39px;
        margin-top: 0px;
    }
    .header-layer-5{
        width: 297px;
        height: 23px;
    }
    .header-layer-5 p{
        margin: auto;
        width: 222px;
        height: 22px;
        color: #000000;
        font-family: 'eurostile_condregular';
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 2.88px;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
    }
    .slider{
        width: 100%;
        margin-top: 80px;

    }
    .slider-card{
        margin: auto;
        width: 80%;
        height: 150px;
    }
    .slider-card-div{
        width: 100%;
        height: 100%;
    }
    .slider-card-text{ 
        font-size: 50px !important;
    }
    .carousel-control-next-icon{
        width: 20px;
        height: 10px;
        margin-left: 20px;
    }
    .carousel-control-prev-icon{
        width: 20px;
        height: 10px;
        margin-right: 20px;
    }
    .carousel-indicators li{
        width: 7px;
        height: 6px;
        border: 8px solid;
        border-radius: 50%;
        background-color: #ffffff;
        opacity: 0.5;
    }
    .carousel-indicators .active{
        border: 8px solid;
        border-radius: 25px;
        width: 15px;
        background: #ffffff;
        outline: none;
    }
    .receiver-title{
        position: absolute;
        left: 20px;
        bottom: 10px;
        font-size: 15px;
    }
    
}