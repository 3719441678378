.our-vision{
    margin-left: 80px;
    margin-right: 80px;
    height: 824px;
}
.our-vision-header{
    margin-top: 46px;
height: 38px;
color: #6c6c6c;
font-family: 'eurostile_condregular';
font-size: 32px;
font-weight: 600;
font-style: normal;
letter-spacing: normal;
line-height: normal;
text-align: left;
text-transform: uppercase;
/* Text style for "KNOW US" */
font-style: normal;
letter-spacing: normal;
line-height: normal;
}

.vision-card{
    margin-top: 43px;
    display: block;
    width: 739px;
    height: auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 26px;
}
.header{
    display: flex;
    width: 100%;
    height: 19px;
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.28px;
    text-align: left;
    text-transform: uppercase;
    padding-top: 42px;
    padding-left: 26px;
}
.text{
    width: 686px;
    height: 149px;
    display: flex;
    padding-top: 14px;
    padding-left: 26px;
    height: 19px;
    color: #5c5c5c;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.28px;
    text-align: left;

}


@media (min-width: 768px) and (max-width: 1024px) {
    .our-vision{
        margin: auto;
        width: 100%;
        padding: 0 16px;
    }
    .vision-card{
        width: 100%;
        height: 100%;
        margin-top: 20px;
        padding: 0 10px;
    }
    .vision-card .text{
        width: 100%;
        height: auto;
        margin-top: 30px;
    }
}


@media screen and (max-width:768px){
    .our-vision{
        margin: auto;
        width: 100%;
        padding: 0 16px;
    }
    .our-vision-header{
        margin-top: 30px;
        font-size: 20px;
    }
    .vision-card{
        width: 100%;
        height: 100%;
        margin-top: 15px;
    }
    .vision-card .text{
        width: 100%;
        height: auto;
        margin-top: 30px;
    }
}