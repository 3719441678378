
.shutguns-row{
    margin: auto;
    background-color:none;
    margin-top: 80px;
    width: 100%;
}
.shutguns-header{
    margin: auto;
    text-align: center;
    margin-top: 80px;
}
.shutguns-header h5{
color: #ffffff;
font-family: 'eurostile_condregular';
font-size: 32px;
font-weight: 600;
text-align: center;
text-transform: uppercase;
}
.shutguns-filter-buttons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0 80px;
    margin-top: 20px;
}
.filter-by{
    width: 100%;
    text-align: center;
    color: white;
    font-size: 14px;
}
.category-buttons-dropdown{
    display: none;
}
.guncards-container{
    width: 100%;
    margin-top: 79px;
    margin-bottom: 124px;
    padding: 0 80px;
}

.gun-card{
    background-color: rgba(69, 69, 69, 0);
    margin: 0 auto 20px auto;
    height: 224px;
    border-radius: 7px;
    border: 2px solid #6a6a6a;
    transition: all ease-in-out 0.2s;
}
.gun-card-div{
    display: flex;
    position: relative;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.gun-card:hover{
    background-color: white;
}
.gun-card-arrow-right{
    display: block;
    position: absolute;
    left: 0%;
    font-size: 28px;
    font-weight: lighter;
    color: rgba(69, 69, 69, 0.055);
    z-index: 100;
    transition: all ease-in-out 0.2s;
    margin: 0;
    height: unset;
    color:rgba(69, 69, 69, 0);
}
.gun-card:hover .gun-card-arrow-right{
    left: 40px;
    color: black
}

.gun-card:hover .shut-gun-img{
    transform: rotate(2deg) scale(1.2);
}
.gun-card:hover .shut-gun-text{
    color: black;
    position: absolute;
    bottom: 20px;
    left: 100px;
}

.coming-soon-text {
    width: 100%;
    display:"flex";
    text-align: center;
    align-self: center;
    color: white;
}
.shut-gun-img{
    position: absolute;
    width: 80%;
    height: 100%;
    object-fit: contain;
    z-index: 2;
    transition: all ease-in-out 0.2s;
    transform-origin: center;
}
.shut-gun-text{
    position: absolute;
    color: #414141;
    font-family: 'eurostile_condregular';
    font-size: 65px;
    font-weight: 600;
    letter-spacing: 5.85px;
    text-align: center;
    text-transform: uppercase;
    z-index: 0;
    bottom: initial;
    left: initial;
    transition: all ease-in-out 0.2s;
}

/* ACCESSORIES */

.swiper-container{
    width: 100%;
}
.swiper-slide{
    margin: 30px 25px 30px 25px;
    width: auto !important;
}
.swiper-wrapper{
    height: auto;
    padding: 0 40px;
}
.swiper-scrollbar{
    display: none;
}
.swiper-button-prev, .swiper-button-next{
    color: black;
}
.swiper-pagination{
    bottom: 0px !important;
}
.swiper-pagination{
    bottom: 0px !important;
}
.swiper-pagination-bullet-active{
    background: black;
    border: 2px solid;
    border-radius: 25px;
    width: 26px;
    outline: none;
}

.accessories-row{
    width: 100%;
    margin: 0 auto 122px auto;
    background-color: #ffffff;
    padding: 0 40px 122px 40px;
    
}
.accessories-header{
    margin: 65px auto 74px auto;
    text-align: center;
}
.accessories-header h5{
color: black;
font-family: 'eurostile_condregular';
font-size: 32px;
font-weight: 600;
text-align: center;
text-transform: uppercase;
margin-bottom: 80px;
}

.accessories-card{
    background-color: rgba(69, 69, 69, 0);
    margin: 0 8px 0px 8px;
    width: 224px;
    height: 325px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.096) ;
}
.accessories-card-choke{
    
    background-color: rgba(69, 69, 69, 0);
    margin: auto;
    width: 224px;
    height: 325px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.096) ;
    z-index: 10;
}

.get-more-btn{
    display: none;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translateX(-50%);
    width: 125px;
    height: 42px;
    color: white;
    background-color: black;
    border: 1px solid #000000;
}

.accessories-img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 70%;
    z-index: 2;
}

.black-header{
    width: 100%;
    position: absolute;
    top: 22px;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 15px;
    font-weight: 600;

}
.grey-text{
    width: 100%;
    position: absolute;
    top:15%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    color: #8e8e8e;
    font-family: 'eurostile_condregular';
    font-size: 15px;
    font-weight: 400;
}

.radius{
    width: 100%;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}

/* HERO-7 */

.hero-7{
    margin: 0 auto;
    text-align: center;
    background: none;
    height: 600px;
}
.hero-7-row{
    width: 100%;
}

.hero-7-social{
    color: #808080;
    font-family: Eurostile;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 56px;
}
.social-accounts a{
    color: #808080;
}
.hero-7-social span{
        padding-left: 80px;
}
.kofs-footer{
        margin-bottom: 56px;
}
.product-cloud{
    position: absolute;
    bottom:0%;
    opacity: 0.2;
    transform: scale(1.1);
}

.dot1{
    position: absolute;
    left: 16px;
    top: 16px;
}
.dot2{
    position: absolute;
    right: 16px;
    top: 16px;
}
.dot3{
    position: absolute;
    left: 16px;
    bottom: 16px;
}
.dot4{
    position: absolute;
    right: 16px;
    bottom: 16px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .guncards-container{
        padding: 0 20px;
    }    
    .shut-gun-img{
        margin-top: 75px;
    }
}



@media screen and (max-width:768px){
    .guncards-container{
        padding: 0 6px;
        margin-top: 34px;
        margin-bottom: 35px;
    }
    .shutguns-header{
        margin-top: 44px;
    }
    .shutguns-header h5{
        width: 100%;
        height: 29px;
        color: #ffffff;
        font-family: 'eurostile_condregular';
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }
    .filter-by{
        display: none;
    }
    .category-buttons-dropdown{
        display: block;
    }
    .gun-card{
        height: 128px;
        margin-bottom: 9px;
    }
    .gun-card:hover{
        
        background-color: rgba(69, 69, 69, 0);
    }
    .gun-card-arrow-right{
        display: none;
    }
    .gun-card:hover .shut-gun-img{
        transform: none;
        transition: none;
        position: absolute;
        margin: auto;
        margin-top: 10%;
        width: 80%;
        z-index: 2;
    }
    .gun-card:hover .shut-gun-text{
        position: absolute;
        margin: auto;
        top: 50%;
        left: 45%;
        transition: none;
        color: #414141;
        font-family: 'eurostile_condregular';
        font-size: 65px;
        font-weight: 600;
        letter-spacing: 5.85px;
        text-align: center;
        text-transform: uppercase;
        z-index: 0;
    }

    .accessories-row{
        padding: 0 16px 40px 16px;
    }
    .accessories-header{
        margin-top: 44px;
        margin-bottom:29px;
    }
    .accessories-header h5{
        text-align: center;
        color: #000000;
        font-family: 'eurostile_condregular';
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        margin: auto;
    }
    .accessories-card{
        margin: 0 0 18px 0;
    }
    .accessories-card-choke{
        margin: 0 0 49px 0;
    }
    .swiper-slide{
        margin: 30px 10px 30px 10px !important;
    }

        
}
