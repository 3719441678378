*,*::before, *:after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'eurostile_condregular';
}
html{
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  
}
body{
  font-family: 'eurostile_condregular';
  position: relative;
  min-height: 100vh;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
footer {
  position: absolute;
  display: flex;
  left: 0;
  width: 100%;
  height: 111px;
  background-color: #ffffff; 
}
.loading-component{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
}
.loading-component img{
  width: 100px;
  height: 100px;
  color: white;
  animation: infinite loadingAnimation 0.8s;
}
@keyframes loadingAnimation{
  0%{
    opacity: 0.5;
    transform: scale(1);
  }
  50%{
    opacity: 1;
    transform: scale(1.2);
  }
  100%{
    opacity: 0.5;
    transform: scale(1);
  }

}
.swal-modal{
  border: none;
  border-radius: 0%;
  width: 50%;
}
.swal-title {
  font-family: 'eurostile_condregular';
  font-size: 40px;
}
.swal-text {
  font-family: 'eurostile_condregular';
  padding: 10px;
  display: block;
  font-size:20px;
  text-align: center;
  color: black;
}
.swal-button{
  border-radius: 0%;
  width: 150px;
  height: 42px;       
  font-family: 'eurostile_condregular';
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.28px;
  text-transform: uppercase;
  border: 1px solid #000000;
  background-color: rgba(24, 24, 14, 0);
  color: #000000;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 1.32px;
  text-align: center;
  text-transform: uppercase;
}
.swal-button:hover{
  color: white;
  background-color: black !important;
}



@media screen and (max-width:768px){
  .swal-modal{
    width: 90%;
  }
}