.nav-black{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: transparent;
    z-index: 100;
    box-sizing: border-box;
    padding: 0 40px;
    border-bottom: 1px solid rgba(209, 209, 209, 0.205);
}

.logo-white{
}
.logo-white .kofs-logo{
    filter: invert(100%);
}
/* .product-bar-black{
    position: absolute;
    top: 61px;
    left:67.5%;
    border-top: 3px solid white;
    border-radius: 2px;
    width: 8%;
} */
.nav-links-black{
    display: flex;
    margin: 0;
}
.nav-links-black li{
    list-style: none;
}
.nav-links-black a{
    color: white;
    white-space: nowrap;
    text-decoration: none;
    font-size: 14px;
    font-family: 'eurostile_condregular';
    height: 80px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    letter-spacing: 2.30px;
    transition: all ease-in-out .3s;
}

.burger-black{
    display: none;
    cursor: pointer;
}

@media screen and (max-width:1024px){
    .nav-links{
        width: 60%;
    }
}

@media screen and (max-width:768px){
    body{
        overflow-x: hidden;
    }
    .logo-white{
        left: 16px;
    }
    .logo-white img{
        width: 80%;
    }
    .nav-black{
        height: 60px;
        padding: 0 16px;
    }
    .nav-links-black{
        position: absolute;
        right: -5%;
        height: 100vh;
        top: 60px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        border: 2px solid grey;
        transform: translate(100%);
        transition: transform 0.5s ease-in;
    }
    .nav-links-black li{
        opacity: 0;
    }
    .burger-black{
        display: block !important;
        position: absolute;
        color: white;
        right: 35px;
        font-size:20px;
    }
    .product-bar-black{
        display: none;
    }

}

.nav-black-side-bar .nav-active-black{
    position: absolute;
    right: 0%;
    height: 91vh;
    top: 60px;
    background: url(../../images/Background.svg);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    border: none;
    transform: translate(0%);
    padding-top: 16px;
}

.nav-active-black li{
    list-style: none;
    transition: transform 1s ease-in;
    margin: 16px;
    margin-right: 28px;
}

.nav-active-black a{
    color: white;
    text-decoration: none;
    font-size: 20px;
    transition: transform 0.3s ease-out;
    font-family: 'eurostile_condregular';
}

