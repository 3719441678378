nav{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: white;
    z-index: 100;
    box-sizing: border-box;
    padding: 0 40px;
}

.logo{

}
.nav-links{
    display: flex;
    margin: 0;
}
.nav-links li{
    list-style: none;
}
.nav-links a{
    color: grey;
    white-space: nowrap;
    text-decoration: none;
    font-size: 14px;
    font-family: 'eurostile_condregular';
    height: 80px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    letter-spacing: 2.30px;
    transition: all ease-in-out .3s;
}
.nav-links a:hover{
    color: #000;

}

.burger{
    display: none;
    cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1024px){
    body{
        overflow-x: hidden;
    }
    nav{
        height: 80px;
    }
    .logo{
        left: 16px;
    }
    .logo img{
        width: 80%;
    }
    .nav-links{
       position: absolute;
       right: -50%;
       height: 100vh;
       top: 60px;
       background-color: white;
       display: flex;
       flex-direction: column;
       align-items: center;
       width: 100%;
       border: 2px solid grey;
       transform: translate(100%);
       transition: transform 0.5s ease-in;
    }
    .nav-links li{
        opacity: 0;
    }
    .burger{
        display: block !important;
        position: fixed;
        right: 24px;
    }
    .nav-close{
        display: none;
    }
}

@media screen and (max-width:768px){
    body{
        overflow-x: hidden;
    }
    nav{
        height: 60px;
        padding: 0 16px;
    }
    .logo-white{
        left: 16px;
        position: absolute;
    }
    .logo img{
        width: 80%;
    }
    .nav-links{
       position: absolute;
       right: -50%;
       height: 100vh;
       top: 60px;
       background-color: white;
       display: flex;
       flex-direction: column;
       align-items: center;
       width: 100%;
       border: 2px solid grey;
       transform: translate(100%);
       transition: transform 0.5s ease-in;
    }
    .nav-links li{
        opacity: 0;
    }
    .burger{
        display: block;
        position: fixed;
        right: 24px;
        font-size: 20px;
    }
    .nav-close{
        display: none;
    }

}

.nav-active{
    position: absolute;
    right: 0;
    height: 91vh;
    top: 60px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    border: 1px solid rgb(201, 201, 201);
    transform: translate(0%);
    padding-top: 16px;
}


.nav-active li{
    list-style: none;
    transition: transform 1s ease-in;
    margin: 16px;
    margin-right: 28px;
}

.nav-active a{
    color: grey;
    text-decoration: none;
    font-size: 20px;
    transition: transform 0.3s ease-out;
    font-family: 'eurostile_condregular';
    
}


