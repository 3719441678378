.receiver-slide{
    position: fixed;
    align-items: center;
    justify-content: center;
    margin-top:-70px;
    width: 100%;
    height: 100vh;
    background-color: white;
    z-index: 100;
}
.slider-close-button{
    position: fixed;
    top: 8%;
    right: 5%;
    color: black;
    font-size: 40px;
    cursor: pointer;
    z-index: 101;
}

.receiver-img{
    margin: auto;
    width:80vw !important;
    height: 100vh;
}
.receiver-slider-container .carousel-control-prev{
    color: black;
    z-index: 101;
    font-size: 40px;
}
.receiver-slider-container .carousel-control-next{
    color: black;
    z-index: 101;
    font-size: 40px;
}

@media screen and (max-width:768px){
    .receiver-slide{
        display: flex;
        position: fixed;
        margin-top: -60px;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        background-color: white;
        z-index: 100;
    }
    .slider-close-button{
        position: fixed;
        top: 8%;
        right: 5%;
        color: black;
        font-size: 40px;
        cursor: pointer;
        z-index: 101;
    }
    .receiver-slider-container .receiver-slide .carosuel-inner{
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .receiver-slider-container .receiver-img{
        width: calc(100vw - 100px)!important;
        height: auto;
        margin: auto;
        object-fit: contain;    
    }
    .receiver-slider-container .carousel-control-prev{
        color: black;
        z-index: 101;
        font-size: 40px;
    }
    .receiver-slider-container .carousel-control-next{
        color: black;
        z-index: 101;
        font-size: 40px;
    }
}