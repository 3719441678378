.suggestions{
    margin-left: 80px;
    margin-right: 80px;
    height: 824px;
}
.suggestions-header{
    margin-top: 46px;
    height: 38px;
    color: #6c6c6c;
    font-family: 'eurostile_condregular';
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
    /* Text style for "KNOW US" */
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
}

.suggestions-card{
    margin-top: 43px;
    display: block;
    height: auto !important;
    width: 740px;
    height: 632px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 26px;
}
.suggestions-item-header{
    display: flex;
    width: 100%;
    height: 19px;
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.28px;
    text-align: left;
    text-transform: uppercase;
    padding-left: 26px;
    padding-top: 42px;
}
.suggestions-item-text{
    display: flex;
    padding-top: 14px;
    padding-left: 26px;
    height: 19px;
    color: #5c5c5c;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.28px;
    text-align: left;
}
.suggestions-item{
    margin-top: 20px;
}

@media screen and (max-width:768px){
    .suggestions{
        margin: auto;
        width: 100%;
        padding: 0 16px;
    }
    .suggestions-header{
        margin-top: 30px;
        margin-bottom: 0;
        font-size: 20px;
    }
    .suggestions-card{
        width: 100%;
        height: 100%;
        margin-top: 15px;
    }
    .suggestions-item-text{
        width: 100%;
        height: auto;
        padding-top: 14px;
        font-size: 12px
    }
    .suggestions-item-header{
        margin-top: 35px;
    }
    .suggestions-item{
        margin-top: 0px;
    }
}