.about-us-container{
    width: 100%;
}
.title-card{
    margin: 80px auto 0 auto;
    height: 217px;
    border: 0px solid #707070;
    background-color: #ffffff;
}
.gun-img{
    position: absolute;
    margin: auto;
    height: 217px;
    width: 600px;
    left: 50%;
    overflow: hidden;
}
.gun{
    position: absolute;
    transform: rotate(10deg) scale(2);
    width: 50%;
    margin: 25px auto auto 130px;
}
.title{
    position: absolute;
    width: 50%;
    height: auto;
    margin: auto;
    margin-left: 80px;
    justify-content: left;
}
.about-us-title{
width: 250px;
height: 52px;
color: #000000;
font-family: 'eurostile_condregular';
font-size: 44px;
font-weight: 600;
font-style: normal;
letter-spacing: normal;
line-height: normal;
text-align: left;
}
.get-more-info{
    width: 400px;
    height: 28px;
    color: #aaaaaa;
    font-family: 'eurostile_condregular';
    font-size: 23px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
}
.title-card button{
width: 108px;
height: 29px;
border-radius: 17px;
background-color: #f8f8f8;
border: 0px;
color: #000000;
font-family: 'eurostile_condregular';
font-size: 14px;
font-weight: 400;
font-style: normal;
letter-spacing: 1.12px;
line-height: normal;
text-align: center;
text-transform: uppercase;
}
.components{
    background-color: #f5f5f5;
    height: 100%;
}
.vision-mission{
    margin-left: 27px;
}

@media screen and (max-width:768px){
    .title-card{
        margin-top: 60px;
    }
    .title{
        position: absolute;
        width: 100%;
        margin-left: 16px;
        z-index: 1;
    }
    .about-us-title{
        font-size: 35px;
        margin-top: 50px;
        margin-left: 0;
    }
    .title .get-more-info{
        font-size: 16px;
    }
    .vision-mission{

        margin-left: 11px !important;
    }
    .title-card button{
        font-size: 12px;
        padding: 0;
        width: 85px;
    }
    .gun-img{
        position: absolute;
        left:15%;
        z-index: 0;
    }
    .gun{
        position: absolute;
        top: 0%;
    }
    footer{
        height: 80px;
    }
    .footer-kofs{
        margin-left: 0 !important;
    }
    .footer-kofs img{
        margin-left: 16px;
    }
    .copyright{
        margin: auto 16px auto auto !important;
    }
    .copyright-by-kofs{
        margin-right: 0px;
        font-size: 14px !important;
    }
    .copyright-2{
        font-size: 14px !important;
        margin: auto 16px !important;
    }
}

