.container{
    width: 100%;
    margin: auto;
}
.homepage-row{
    width: 100%;
    height: auto;
    /*background: url(../images/homepage-background.png);*/
}

/* HERO-1 */
.hero-1{
    overflow: hidden;
    display: flex;   
    width: 100%;
    margin: auto;
    height: 100vh;
    /* background: url(../images/hero-1.jpg) ; */
    transform: scaleX(-1);
    background-size: cover;
    object-fit: cover;
    margin-top: 0%;
    z-index: 0;
}

.home-gun-1{
    position: absolute;
    margin: auto;
    top: -7%;
    left: -27%;
    width: 100%;
    transform: scaleX(-1) rotate(20deg);
}
.hero-1-background{
    width: 100%;
    height: 100vh;
    object-fit: cover;
    transform: scaleX(-1);
}
.hero-1 .carousel{
    z-index: -1;
    width: 100%;
}

.cloud-img{
    position: absolute;
    margin: auto;
    bottom: 0;
    width: 100%;
    height: 960px;
    z-index: 2;
    transform:scaleX(-1);
}

.arrow-down{
    display: flex;
    position: absolute;
    bottom:20px;
    left: 50%;
    z-index: 5;
    color: rgba(70, 68, 68, 0.699);
    
}
/* HERO-2 */
.hero-2{
    width: 100%;
    margin: 0 auto;
    height: auto;
    background-size: cover;
    z-index: 0;
    border-top: 1px solid rgb(221, 221, 221); 
}
.hero-2-background{
    width: 100%;
    height: auto;
    object-fit: cover;
}
/* HERO-3 */
.hero-3{
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: 1294px;
    background-image: linear-gradient(to top, rgba(24, 24, 14, 0) 0%, rgba(24, 24, 14, 0.85) 31%, #18180e 100%);
    background-size: cover;
    z-index: 0;
}

.home-gun-2{
    position: absolute;
    width: 100%;
    margin-top: 2%;
    margin-left: 10%;
    transform:scale(1.4) rotate(335deg);
    z-index: 0;
}


/* HERO-4 */
.hero-4{
    display: flex;
    width: 100%;
    margin: auto;
    height: auto; 
    z-index: 0;
}
.models-accessories-row{
    width: 100%;
    height: auto;
}


.guns-row{
    width: 100%;
    display: flex;
    justify-content: center;
}
.gunswrapper{
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    
}
.gunswrapper-container{
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}
.guns-textBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    max-width: 300px;
}
.guns-textBox h5{
    margin-top: 60px;
    color: #ffffff;
    font-size: 51px;
    font-weight: 700;
    margin-bottom: 0px;
    font-style: normal;
    letter-spacing: 6.12px;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 25%;
    z-index: 4;
    font-family: 'eurostile_condregular';
}
.guns-textBox p{
    margin-top: 50px;
    width: 250px;
    height: 54px;
    opacity: 0.64;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    z-index: 4;
    font-family: 'eurostile_condregular';
}
.get-more-button-cavalry{
    margin-top: 20px;
    width: 125px;
    height: 42px;
    border: 1px solid rgba(255, 255, 255, 0.14);
    background-color: rgba(24, 24, 14, 0.1); 
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 11px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.32px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    z-index: 10;
}
.get-more-button-cavalry:hover{
    background-color: white;
    color: black;
}
.shooting-range{
    position: absolute;
    left: -1374px;
    width: 2749px;
    height: 2749px;
    z-index: -10;
    opacity: 0.2;
}
.guns-card-wrapper{
    display: flex;
}
.guns-card{
    display: block;
    position: relative;
    margin: 0 40px;
    width: 349px;
    height: 579px;
    outline: 17px solid rgba(255, 255, 255, 0.1);
    outline-width: 1px solid rgba(255, 255, 255, 0.42);
    mix-blend-mode: multiply;
    background-color: black;
    z-index: 3;
}
.guns-card-background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.popular-1-h5{
    position: absolute;
    bottom: 20%;
    left:-25%;
    transform: rotate(270deg);
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 52px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 9.36px;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
    z-index: 4;
}
.popular-2-h5{
    position: absolute;
    bottom: 20%;
    left:-10%;
    transform: rotate(270deg);
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 52px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 9.36px;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
    z-index: 4;
}
.guns-card p{
    position: absolute;
    bottom: 0%;
    right: 20px;
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 2.08px;
    text-align: right;
    text-transform: uppercase;
    z-index: 4;

}

.gun-models{
    position: absolute;
    margin: auto;
    top: 45%;
    left: -85%;
    width: 700px;
    height: 126px;
    z-index: 10;
}
.gun-models-2{
    position: absolute;
    margin: auto;
    top: 40%;
    left: -50%;
    width: 700px;
    height: 126px;
    transform:rotate(90deg);
    z-index: 10;
}
.arrow-right-see-modals{
    margin: auto;
}
.see-all-models-link{
    transform: rotate(90deg);
    margin-left: -30px;
}
.see-all-models-text{
    display: block;
    font-family: 'eurostile_condregular';
    color: white;
    text-align: center;
    white-space: nowrap;
}

/* ACCESSORY SECTION */
.accessories-section{
    margin-top: 100px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-size: cover;
    z-index: 3;
    /* background-image: linear-gradient(180deg, rgba(24, 24, 14, 0) 0%, rgba(24, 24, 14, 0.85) 31%, #18180e 100%); */
}
.table-textBox{
    position: relative;
    z-index: 4;
    height: auto;
    z-index: 10;
}

.table-textBox h5{
    color: #ffffff;
    font-size: 51px;
    font-weight: 700;
    font-family: 'eurostile_condregular';
    margin-bottom: 16px;
    font-style: normal;
    letter-spacing: 6.12px;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
    text-decoration: none;
}
.table-textBox p {
    max-width: 300px;
    margin-right: -150px;
    width: 300px;
    height: 54px;
    opacity: 0.64;
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    text-decoration: none;
  }
  .table-imageBox{
    margin-right: -30%;
  }
.table-imageBox img {
    width: 80vw;
   
  }
.get-more-button-accessory{
            width: 125px;
            height: 42px;
            border: 1px solid rgba(255, 255, 255, 0.14);
            background-color: rgba(24, 24, 14, 0.1); 
            color: #ffffff;
            font-family: 'eurostile_condregular';
            font-size: 11px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 1.32px;
            line-height: normal;
            text-align: center;
            text-transform: uppercase;
            z-index: 100;

}
.get-more-button-accessory:hover{
    color: black;
    background-color: white;
}
/* HERO-6 */
.hero-6{
    display: flex;
    width: 100%;
    margin:auto;
    height: 1000px;
    background-size: cover;
    z-index: 6;
    margin-bottom: 200px;
}
.feel-the-hunting{
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    
}
.waves{
    position: absolute;
    margin: auto;
    width: 100%;
    transform: scaleX(-1) scale(1.5) rotate(12deg);
}

/* HERO-7 */
.hero-7{
    margin: auto ;
    height: auto;
    z-index: 7;
}

.cloud-img-2{
    width: 100%;
}
.footer-header{
    margin: 0 auto;
    text-align: center;
    
}
.footer-header h5{
    margin: auto;
    color: #ffffff;
    font-size: 51px;
    font-weight: 700;
    font-family: 'eurostile_condregular';
    margin-bottom: 10px;
    font-style: normal;
    letter-spacing: 6.12px;
    line-height: normal;
    text-transform: uppercase;
}
.footer-header p{
    margin: auto;
    width: 300px;
    height: 54px;
    opacity: 0.64;
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
}
.world-map{
    width: 100%;
    height: auto;
    margin: 10% auto;
    z-index: 7;
}
.social{
    display: block;
    width: 100%;
    margin: 85px auto auto auto;
    text-align: center;
    color: #808080;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}
.social p{
    font-family: 'eurostile_condregular';
}
.social a{
    color: #808080;
}
.social span{
    margin-left: 80px;
    font-family: 'eurostile_condregular';
}
.social img{
    margin-top: 56px;
    margin-bottom: 50px;
}

.kofs-footer{
    margin: auto;
}
.copyright{
    display: block;
    margin: auto;
}
.copyright p{
    margin: auto;
    text-align: center;
    color: #e3e3e3;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}
.design-by{
    color: #808080 !important;
    font-family: 'eurostile_condregular';
}
.footer-cloud{
    margin: auto;
    width: 100%;
}
.footer-cloud img{
    margin: auto;
    position: relative;
    bottom: 0%;
    width: 100%;
    z-index: -1;
}
@media (min-width: 1400px) and (max-width: 1441px){
    .homepage-row{
        height: auto;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {

    .homepage-row{
        height: auto;
    }
    .hero-2{
        height: 550px;
    }
    .hero-3{
        height: 800px;
    }
    .hero-4{
        height: 1300px;
    }
    .hero-6{
        height: 600px;
        margin-bottom: 50px;
    }
    .cavalry{
        margin-left: 50%;
    }
    .sceptre{
        display: none;
    }
    .see-all-models-text{
        display: none;
    }
    .arrow-right-see-modals{
        display: none;
    }
    .get-more-button-cavalry{
        display: block;
        position: absolute;
        padding: 15px 30px 13px 30px;
        left: 8%;
        top: 33%;
        width: 200px;
        height: 42px;
        border: 1px solid rgba(255, 255, 255, 0.14);
        background-color: rgba(24, 24, 14, 0.1); 
        color: #ffffff;
        font-family: 'eurostile_condregular';
        font-size: 11px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 1.32px;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
        z-index: 10;

    }
    .accessories-table-text{
        margin-left: -3%;
    }
    .get-more-button-accessory{
        display: block;
        position: absolute;
        padding: 15px 30px 13px 30px;
        left: 8%;
        top: 85%;
        width: 200px;
        height: 42px;
        border: 1px solid rgba(255, 255, 255, 0.14);
        background-color: rgba(24, 24, 14, 0.1); 
        color: #ffffff;
        font-family: 'eurostile_condregular';
        font-size: 11px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 1.32px;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
        z-index: 10;
    }
    .feel-the-hunting{
        width: 70%;
    }
}

@media screen and (min-width:1920px){
    .models-accessories-row{
        margin-top: 300px;
    }
    .accessories-section{
        margin-top: 200px;
    }
    .gunswrapper{
        transform: scale(1.4);
    }
    .hero-6{
        margin-bottom: 700px;
    }
}

@media screen and (max-width:768px){
    .homepage-row{
        height: auto;
    }
    /* HERO-1 */
    .hero-1{
        width: 100%;
        height: 35vh;
        margin-top: 60px;
    }
    .hero-1 .carousel-fade .carousel-item{
        width: 100%;
        height: 100%;
    }
    /* .hero-1 .carousel-fade .carousel-item img{
        object-fit: cover;
        width: 100%;
    } */
    .hero-1-background{
        margin-top: 0;
        height: 35vh;
        width: 100%;
        object-fit: cover;
    }
    .arrow-down{
        display: none;
    }
    .cloud-img{
        height: auto;
        bottom: -2px;
    }
    /* HERO-2 */
    .hero-2{
        border: none;
    }
    /* HERO-3 */
    .hero-3{
        height: 338px;
        margin-top: 0px;
    }
    .home-gun-2{
        top: 2%;
    }
    /* HERO-4 */
    .hero-4{
        height: 533px;
    }
    .models-accessories-row{
        height: auto;
    }
    .gunswrapper-container{
        margin: auto;
    }
    .gunswrapper{
        z-index: 0;
    }
    .guns-row{
        height: 235px;
    }
    .guns-card{
        width: 142px;
        height: 235px;
    }
    .guns-textBox{
        max-width: 120px;
    }
    #second-guns-card{
        display: none;
    }
    .guns-textBox h5{
        font-size: 25px;
    }
    .guns-textBox p{
        font-size:12px ;
        width: 140px;
        margin-top: 25px;
    }
    .guns-card h5{
        position: absolute;
        top: 60%;
        left: 35%;
        width: 27px;
        height: 119px;
        color: #ffffff;
        font-family: 'eurostile_condregular';
        font-size: 21px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 3.78px;
        line-height: normal;
        text-align: left;
        text-transform: uppercase;
    }
    .guns-card p{
    width: 80px;
    height: 8px;
    color: #ffffff;
    font-family: 'eurostile_condregular';
    font-size: 10px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.78px;
    line-height: normal;
    text-align: right;
    text-transform: uppercase;
    }
    .gun-models{
        position: absolute;
        left: -10%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 333px;
        height: 52px;
        margin: auto;
    }
    .arrow-right-see-modals{
        display: none;
    }
    .see-all-models-text{
        display: none;
    }
    .shooting-range{
        transform: scale(0.3);
    }
    .get-more-button-cavalry{
        margin-top: 10px;
        width: 125px;
        height: 42px;
        border: 1px solid rgba(255, 255, 255, 0.14);
        background-color: rgba(24, 24, 14, 0.1); 
        color: #ffffff;
        font-family: 'eurostile_condregular';
        font-size: 11px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 1.32px;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
    }
        /* Accessories */
        .accessories-section{
            position: absolute;
            height: 257px;
            margin: 80px auto auto -5%;
        }
        .table-textBox{
            max-width: 120px;
        }
        .table-textBox h5{
            font-size: 21px;
        }
        .table-textBox p{
            width: 150px;
        }
        .tableAccsryImg{
            position: absolute;
            margin-top: 50px;
            left: 25%;
        }
        .get-more-button-accessory{
            display: block;
            margin-top: 30px;
            width: 125px;
            height: 42px;
            border: 1px solid rgba(255, 255, 255, 0.14);
            background-color: rgba(24, 24, 14, 0.1); 
            color: #ffffff;
            font-family: 'eurostile_condregular';
            font-size: 11px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 1.32px;
            line-height: normal;
            text-align: center;
            text-transform: uppercase;
        }
        /* HERO-6 */
        .hero-6{
            height: 459px;
            margin: auto;
        }
        .waves{
            position: absolute;
            top: 50%;
            left: 0;
            transform: rotate(350deg) translateY(-50%) scale(1.2) scaleX(-1);
            width: 100%;
    
        }
        /* HERO-7 */
        .feel-the-hunting{
            width: 50%;
        }
        .footer-header h5{
            width: 109px;
            height: 29px;
            color: #ffffff;
            font-family: 'eurostile_condregular';
            font-size: 24px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: 2.88px;
            line-height: normal;
            text-align: center;
            text-transform: uppercase;
        }
        .footer-header p{
            width: 291px;
            height: 54px;
            opacity: 0.64;
            color: #ffffff;
            font-family: 'eurostile_condregular';
            font-size: 12px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: center;
        }   
}

