.support-card-container{
    width: 100%;
}
.support-card{
    margin: auto;
    margin-top: 80px;
    height: 217px;
    border: 0px solid #707070;
    background-color: #ffffff;
    overflow-y: hidden;
}
.support-gun-img{
    position: absolute;
    margin: auto;
    height: 217px;
    width: 600px;
    left: 50%;
    overflow: hidden;
}
.support-gun{
    position: absolute;
    transform: rotate(10deg) scale(2);
    width: 50%;
    margin: 25px auto auto 130px;
}

.support{
    position: absolute;
    width: 100%;
    height: auto;
    margin: auto;
    margin-left: 80px;
    justify-content: left;
}
.about-us-support{
    width: 250px;
    height: 52px;
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 44px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
}
.get-more-info{
    width: 400px;
    height: 28px;
    color: #aaaaaa;
    font-family: 'eurostile_condregular';
    font-size: 23px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
}
.support-card button{
    height: 29px;
    border-radius: 17px;
    background-color: #f8f8f8;
    padding: 7px 20px 6px 20px;
    border: 0px;
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.12px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
}
.components{
    background-color: #f5f5f5;
    height: 100%;
}
.vision-mission{
    margin-left: 27px;
}
.support .vision-mission-manual{
    margin-left: 27px;
}

@media screen and (max-width:768px){
    .support-card{
        margin-top: 60px;
    }
    .support{
        position: absolute;
        width: 100%;
        margin-left: 16px;
        z-index: 1;
    }
    .about-us-support{
        font-size: 35px;
        margin-top: 50px;
        margin-left: 0;
    }
    .support .get-more-info{
        font-size: 16px;
    }
    .support .vision-mission{
        margin-left: 7px !important;
    }
    .support-card button{
        font-size: 9px;
        padding: 0 7px;
        width: 75px;
    }
    .support-gun-img{
        position: absolute;
        left:15%;
        z-index: 0;
    }
    .support-gun{
        position: absolute;
        top: 0%;
    }
    .support .vision-mission-manual{
        width: 85px;
        margin-left: 7px;
    }

}
