/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 11, 2020 */



@font-face {
    font-family: 'eurostile_condheavy_italic';
    src: url('eurostile_condensed-heavy_italic-webfont.woff2') format('woff2'),
         url('eurostile_condensed-heavy_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'eurostile_condheavy';
    src: url('eurostile_condensed-heavy-webfont.woff2') format('woff2'),
         url('eurostile_condensed-heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'eurostile_condregular_italic';
    src: url('eurostile_condensed-regular_italic-webfont.woff2') format('woff2'),
         url('eurostile_condensed-regular_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'eurostile_condregular';
    src: url('eurostile_condensed-regular-webfont.woff2') format('woff2'),
         url('eurostile_condensed-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'eurostile_extdblack_italic';
    src: url('eurostile_extended-black_italic-webfont.woff2') format('woff2'),
         url('eurostile_extended-black_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'eurostile_extdblack';
    src: url('eurostile_extended-black-webfont.woff2') format('woff2'),
         url('eurostile_extended-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'eurostile_extdmedium';
    src: url('eurostile_extended-medium-webfont.woff2') format('woff2'),
         url('eurostile_extended-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'eurostile_extdregular_italic';
    src: url('eurostile_extended-regular_italic-webfont.woff2') format('woff2'),
         url('eurostile_extended-regular_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'eurostile_extdregular';
    src: url('eurostile_extended-regular-webfont.woff2') format('woff2'),
         url('eurostile_extended-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'eurostileblack_italic';
    src: url('eurostile-black_italic-webfont.woff2') format('woff2'),
         url('eurostile-black_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'eurostileblack';
    src: url('eurostile-black-webfont.woff2') format('woff2'),
         url('eurostile-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'eurostilebold_oblique';
    src: url('eurostile-bold_oblique-webfont.woff2') format('woff2'),
         url('eurostile-bold_oblique-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}