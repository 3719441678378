.manuals{
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 432px;
    height: 1080px;
}
.manuals-header{
    margin-top: 46px;
    height: 38px;
    color: #6c6c6c;
    font-family: 'eurostile_condregular';
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
    /* Text style for "KNOW US" */
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
}

.manuals-card{
    margin-top: 43px;
    display: block;
    width: 740px;
    height: 339px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    background-color: #ffffff;
    padding-left: 30px;
    padding-right: 30px;
}

.manuals-item-header{
    display: flex;
    width: 100%;
    height: 19px;
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.28px;
    text-align: left;
    text-transform: uppercase;
    padding-top: 42px;
    padding-bottom: 22px;
}
.manual-menus .collapse{
    margin-bottom: 30px;
}
.manuals-item{
    height: 100%;
    width: 100%;
    margin: 42px auto auto auto;
}
.user-manuals-button{
    height: 29px;
    border-radius: 17px;
    background-color: #EAEAEA;
    padding: 7px 20px 7px 20px;
    margin-right: 16px;
    border: 0px;
    color: #000000;
    font-family: 'eurostile_condregular';
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.12px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
}
.user-manuals-button-active{
    height: 29px;
    border-radius: 17px;
    background-color: black;
    padding: 7px 20px 7px 20px;
    margin-right: 16px;
    border: 0px;
    color: white;
    font-family: 'eurostile_condregular';
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.12px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
}
.manals-hr{
    width: 90%;
    margin-top: 13.5px;
    margin-bottom: 12.5px;
}
.pdf-section{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    background: #fafafa 0% 0% no-repeat padding-box;
    border-radius: 15px;
    text-align: left;
    margin-bottom: 9px;
    cursor: pointer;
}

.pdf-section-text{
    padding-left: 14px;
    font-family: 'eurostile_condregular';
    font-size: 14px;
    letter-spacing: 1.12px;
    color: #000000;
}
.pdf-download-text{
    position: absolute;
    right: 4%;
    padding-top:10px ;
    font-family: 'eurostile_condregular';
    font-size: 14px;
    letter-spacing: 1.12px;
    color: #000000;
    
}


.manual-menus .card{
    margin: auto;
    color: white;
    background-color: white;
    border-radius: 19px;
}
.plus-button{
    border: none;
    position: absolute;
    margin: auto;
    right: 20px;
    font-size: 20px;
    background-color: none;
}
.manual-menus button:focus {outline:0;}

@media screen and (max-width:768px){
    .manuals{
        margin: auto;
        width: 100%;
        padding: 0 16px;
    }
    .manuals-header{
        margin-top: 30px;
        margin-bottom: 0;
        font-size: 20px;
    }
    .manuals-card{
        width: 100%;
        height: 100%;
        margin-top: 0px;
        padding-top: 0;
    }
    .pdf-section{
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
    }
    .pdf-section-text{
        width: 75%;
        font-size: 12px;
    }
    .pdf-download-text{
        display: block;
        position: absolute;
        margin-top: -8%;
        right: 25px;
        text-align: center;
        font-size: 8px;
        width: 50px;
        
    }
   
}